<section class="section-scheme">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-md-8 col-sm-12 col-12">
				<div class="page-lbar">
					<h2 class="left-header navy-blue">You Become a Volunteer</h2>
					<li class="left-hd-list cursr-none"></li>
				</div>
				<div class="page-lbar-p cursr-none">
					<p class="sm-txt"  >“If our hopes of building a better and safer world are to become more than wishful thinking, we will need the engagement of volunteers more than ever.” — Kofi Annan</p>
				</div>

			</div>
			<div class="clearfix"></div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="volunter-form">
					<div class="page-lbar">
						<!-- <h2 class="left-header txt-white">You become a volunteer</h2> -->
						<li class="left-hd-list txt-white">Fill your details</li>
					</div>
          <form [formGroup]="createVolunteerForm">

					<div class="row">
						<div class="col-md-6 col-lg-6 col-sm-12 col-12">
							<input type="text" name="" class="vtr-dt-box mb-20 form-control inp-backcolor" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f2.username.errors }" placeholder="Your Name*">
              <div *ngIf="submitted && f2.username.errors" class="invalid-feedback">
                <div *ngIf="f2.username.errors.required">This field is required</div>

                </div>
						</div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-12">
							<input type="text" name="" class="vtr-dt-box mb-20 form-control inp-backcolor" maxlength="16" minlength="8" formControlName="phone_number" [ngClass]="{ 'is-invalid': submitted && f2.phone_number.errors }" placeholder="Phone Number*">
              <div *ngIf="submitted && f2.phone_number.errors" class="invalid-feedback">
                <div *ngIf="f2.phone_number.errors.required">This field is required</div>
                <div *ngIf="(f2.phone_number.errors.pattern || phoneerror === true) && this.createVolunteerForm.value.phone_number ">Please, Enter valid Phone Number.</div>


                </div>
						</div>
						<div class="mt-4 col-md-6 col-lg-6 col-sm-12 col-12 ">
							<input type="text" name="" class="vtr-dt-box mb-20 form-control inp-backcolor" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f2.address.errors }" placeholder="Address*">
              <div *ngIf="submitted && f2.address.errors" class="invalid-feedback">
                <div *ngIf="f2.address.errors.required">This field is required</div>

                </div>
            </div>
              <div class=" mt-4 col-md-6 col-lg-6 col-sm-12 col-12">

							<input type="text" name="" class="vtr-dt-box mb-20 form-control inp-backcolor" [email]="true"  formControlName="email" [ngClass]="{ 'is-invalid': submitted && f2.email.errors }" placeholder="Email*">
              <div *ngIf="submitted && f2.email.errors" class="invalid-feedback">
                <div *ngIf="f2.email.errors.required">This field is required</div>
                <div *ngIf="f2.email.errors.pattern">Please, Enter valid email.</div>


                </div>
              <button class="btn-wd-100 mt-4" type="sumit" (click)="submitVolunteer()"><i class="fa" [ngClass]="{'fa-spin fa-spinner': loading}"></i> Submit</button>
						</div>
					</div>
</form>
				</div>
				<div class="volunteer-img"><img src="assets//images/icons/volunteer.png" class="img-fluid"></div>
			</div>
		</div>
	</div>
</section>
<div class="modal fade payed-modal" id="successvolunteerModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="background-color: white;">
      <div class="modal-body">
        <div class="col-lg-12 col-lg-12">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="payd-msg">
                <p class="mt-4" style="color: black;text-transform: none;">
                  Your application submitted successfully. Our representative will contact you soon.</p>
                <div class="row">
                  <div class="col-md-2 col-12"></div>
                  <div class="col-md-4 col-12" style=" margin: 0 auto;">
                    <button class="f-btn-border" type="submit" data-dismiss="modal" style="background-color: #32374a;" >OK</button>
                  </div>

                  <div class="col-md-2 col-12"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

