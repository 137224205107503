<footer>
	<section class="section-footer">
		<div class="container">
			<div class="border-sec">
				<div class="row">
					<!--Footer Widget start-->
					<div class="footer-widget col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-20">
						<div class="logo-footer">
							<img src="assets/images/logo/logo-white.png" class="img-fluid">
						</div>
						<!-- <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there
						 isn't anything embarrassing hidden in the middle of text.
						</p> -->

					</div>
					<!--Footer Widget end-->

					<div class="col-md-9">
						<div class="row">
							<div class="footer-widget col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-20 ">
								<h4 class="title cursr-pointer">Quick Links</h4>
								<ul class="ft-menu">
										<li >
										<a routerLink='/home' >Home</a>
									  </li>
									  <li >
										<a routerLink='/about-us' >About Us</a>
									  </li>
									  <li >
										<a routerLink='/campaigns'>Campaigns</a>
									  </li>
									  <li >
										<a routerLink='/campaigns'>volunteers with Us</a>
									  </li>
									<!-- <li >
										<a (click)="scrollToElement('#vision')" >Section1</a>
									  </li>
									<li><a  [routerLink]="['/about-us']" fragment="vision">Vision</a></li>
									<!-- <a [routerLink]="['/target-page']" fragment="section-to-jump">Jump to Section</a> -->
									<!-- <li><a fragment="vision" (click)="navigateToSection('about-us#vision')">About</a></li> -->
									<!-- <li><a (click)="scrollToElement()">mission</a></li> --
									<li><a routerLink="/about-us" >our teams</a></li>
									<li><a routerLink="/about-us" >Partners</a></li>
                  <li><a routerLink="/about-us">Media</a></li> -->


								</ul>
							</div>
              <!-- <div class="footer-widget col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-20 ">
								<h4 class="title cursr-pointer" ><a href="" routerLink="/howItWorks"></a>How it works</h4>
								<ul class="ft-menu">
									<li><a routerLink="/howItWorks">How to start a fundraiser</a></li>
									<li><a routerLink="/howItWorks" >How to Donate</a></li>
									<li><a routerLink="/howItWorks" >Pricing</a></li>
									<li><a routerLink="/howItWorks" >FAQ</a></li>

								</ul>
							</div> -->
							<div class="footer-widget col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-20 ">
								<h4 class="title cursr-pointer">Policy</h4>
								<ul class="ft-menu">
									<li><a routerLink="/Aml-policy">AML Policy</a></li>	
          <li><a  routerLink="/privacy-policy" >Privacy Policy </a></li>
		  <li><a routerLink="/terms-and-conditions">Terms of Use</a></li>
		  <li><a routerLink="/cancellation-policy">Cancellation Policy </a></li>
		
          
          
									<!-- <a href="#campaigns">Education</a></li>
									<li><a href="#campaigns">Health care</a></li>
									<li><a href="#campaigns">Women empowerment</a></li>
                  <li><a href="#campaigns">Poverty & Humanitarian</a></li> -->
								</ul>
							</div>

							<div class="footer-widget col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  ">
								<h4 class="title cursr-pointer">Contact US</h4>
								<ul style="color:#fff">
									<!-- <li><i class="fa fa-map-marker"></i> CROWD WORKS INDIA FOUNDATION,<br>
										ALAMPARAMBIL BUILDING, 13/1013-5,
										2ND FLOOR, TK ROAD, THIRUVALLA,KERALA, 689101.</li>
									 -->
									<li><i class="fa fa-envelope-o"></i>info@crowdWorksIndia.org</li>
									<li><i class="fa fa-phone-square"></i> +91 99460 31111</li>
									
								</ul>
								
								
								
							</div>
						</div>
					</div>

				</div>
				<!--Footer Widget end-->
			</div>
		</div>

	</section>
	<section class="footer-botm cursr-pointer">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12">
					<p> Copyright © All rights reserved 2021. <span>Proudly powered by Cocoalabs Pvt Ltd.</span>
          
          </p>
				</div>
			</div>
		</div>
	</section>
</footer>

