<section class="start-fundraiser">
	<div class="container">
		<div class="row">
      <form [formGroup]="startFundraiserForm">
			<div class="col-md-12 col-lg-12">
				<div class="Fund-hd">
					<h2>Start a fundraiser</h2>
				</div>
			</div>
			<div class="col-md-12 col-sm-12 col-12">
				<div class="st-fd-form">
					<h3>personal details</h3>
					<div class="form-row">
          	  <div class="form-group col-md-6 col-sm-6">
            		<input type="text" class="st-fd-tbox"  placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && fundr2.name.errors }">
                <div *ngIf="submitted && fundr2.name.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.name.errors.required">This field is required</div>
                  </div>
            	</div>
            	<div class="form-group col-md-6 col-sm-6">
            		<input type="text" class="st-fd-tbox"  placeholder="Email" formControlName="email" [email]="true"  [ngClass]="{ 'is-invalid': submitted && fundr2.email.errors }">
                <div *ngIf="submitted && fundr2.email.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.email.errors.required">This field is required</div>
                  <div *ngIf="fundr2.email.errors.pattern">Please, Enter valid email.</div>
                  </div>
            	</div>
            	<div class="form-group col-md-6 col-sm-6">
            		<!-- <input type="text" class="st-fd-tbox"  placeholder="Phone number" formControlName="phone_number" [ngClass]="{ 'is-invalid': submitted && fundr2.phone_number.errors }">
                <div *ngIf="submitted && fundr2.phone_number.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.phone_number.errors.required">This field is required</div>
                  </div> -->
                  <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.India" [maxLength]="10"
                  [phoneValidation]="true" [separateDialCode]="separateDialCode" style="font-weight: 700 !important;"
                  [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone" (input)="checkphoneNumValidation()">
                </ngx-intl-tel-input>
                <div *ngIf="startFundraiserForm.value.phone==null && submitted">
                  <div style="color:#dc3545;font-size: 80%;">This field is required</div>

                    </div>
                    <div *ngIf="phoneerror === true" style="color:#dc3545;font-size: 80%;">Please enter valid phone number</div>

              </div>
            	<div class="form-group col-md-6 col-sm-6">
            		<select class="st-fd-tbox"  placeholder="Full name" formControlName="relation" [ngClass]="{ 'is-invalid': submitted && fundr2.relation.errors }" >
            			<option value='' style="font-weight: 200 !important;">Choose relation</option>
            			<option *ngFor="let item of relationList" value="{{item.id}}">{{item.title}}</option>
            		</select>
                <div *ngIf="submitted && fundr2.relation.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.relation.errors.required">This field is required</div>
                  </div>
              </div>

          </div>
				</div>
			</div>
			<div class="col-md-12 col-sm-12 col-12">
				<div class="st-fd-form">
					<h3>Benificiary informations</h3>
					<div class="bf-box">
						<div class="row">
							<div class="col-md-8 ml-auto mr-auto">
								<div class="form-row align-items-center">
                  	<div class="col-md-6 col-sm-6">
                  		<p>Purpose of raising fund</p>
                  	</div>
                  	<div class="col-md-6 col-sm-6">
                      <input type="text" class="st-fd-tbox"  placeholder="purpose" style="cursor: none;" formControlName="category" readonly [(ngModel)]="categoryName">
                  		<!-- <select class="st-fd-tbox"  placeholder="Full name" formControlName="category" (change)="changeCampaign()"   [ngClass]="{ 'is-invalid': submitted && fundr2.category.errors }"  >
                  			<option value=''>Select Campaign</option>
                  			<option *ngFor="let item of categoryList" [ngValue]="item" [selected]="item.id === campaign_id" ></option>
                  		</select> -->
                      <div *ngIf="submitted && fundr2.category.errors" class="invalid-feedback">
                        <div *ngIf="fundr2.category.errors.required">This field is required</div>
                        </div>
                  	</div>
                </div>
							</div>
						</div>	</div>

					<div class="form-row">
          	  <div class="form-group col-md-6 col-sm-6">
            		<input type="text" class="st-fd-tbox-cp"  placeholder="Full Name" formControlName="patient_name" [ngClass]="{ 'is-invalid': submitted && fundr2.patient_name.errors }">
                <div *ngIf="submitted && fundr2.patient_name.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.patient_name.errors.required">This field is required</div>
                  </div>
              </div>
            	<div class="form-group col-md-6 col-sm-6" *ngIf="is_health_case == 1">
            		<input type="text" class="st-fd-tbox-cp" placeholder="What is the health issue?" formControlName="health_issue" [ngClass]="{ 'is-invalid': submitted && fundr2.health_issue.errors }">
                <div *ngIf="submitted && fundr2.health_issue.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.health_issue.errors.required">This field is required</div>
                  </div>
              </div>
            	<div class="form-group col-md-6 col-sm-6" *ngIf="is_health_case == 1">
            		<input type="text" class="st-fd-tbox-cp"  placeholder="Which hospital" formControlName="hospital_name" [ngClass]="{ 'is-invalid': submitted && fundr2.hospital_name.errors }">
                <div *ngIf="submitted && fundr2.hospital_name.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.hospital_name.errors.required">This field is required</div>
                  </div>
              </div>
            	<div class="form-group col-md-6 col-sm-6" *ngIf="is_health_case == 1">
            		<input type="text" class="st-fd-tbox-cp"  placeholder="City" formControlName="city" [ngClass]="{ 'is-invalid': submitted && fundr2.city.errors }">
                <div *ngIf="submitted && fundr2.city.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.city.errors.required">This field is required</div>
                  </div>
              </div>
            	<div class="form-group col-md-6 col-sm-6">
            		<input type="text" class="st-fd-tbox-cp" maxlength="3"  placeholder="Minimum Number of days for amount collection" formControlName="noof_days" [ngClass]="{ 'is-invalid': submitted && fundr2.noof_days.errors }">
                <div *ngIf="submitted && fundr2.noof_days.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.noof_days.errors.required">This field is required</div>
                  <div *ngIf="fundr2.noof_days.errors.pattern" style="color: red;">Days must be a Number</div>
                  </div>

              </div>
              </div>
            	<div class="form-row">
                <div class="form-group col-md-6 col-sm-6">
                    <input type="text" class="st-fd-tbox-cp"  placeholder="Rs. 20,000" maxlength="9" formControlName="amount" [ngClass]="{ 'is-invalid': submitted && fundr2.amount.errors }">
                    <div *ngIf="submitted && fundr2.amount.errors" class="invalid-feedback">
                      <div *ngIf="fundr2.amount.errors.required">This field is required</div>
                      <div *ngIf="fundr2.amount.errors.pattern" style="color: red;">Amount accepting only Number and Comma</div>

                      </div>

                      <!-- <div *ngIf="fundr2.amount.errors.pattern" style="color: red;">Days must be a Number</div> -->
                  </div>
                  <div class="form-group col-md-6 col-sm-6">
                    <input type="text" class="st-fd-tbox-cp"  placeholder="Title" formControlName="title" [ngClass]="{ 'is-invalid': submitted && fundr2.title.errors }">
                    <div *ngIf="submitted && fundr2.title.errors" class="invalid-feedback">
                      <div *ngIf="fundr2.title.errors.required">This field is required</div>
                      </div>
                  </div>
                  <div class="form-group col-md-12 col-sm-12">
                    <input type="text" class="st-fd-tbox-cp1"  placeholder="Description" formControlName="description" [ngClass]="{ 'is-invalid': submitted && fundr2.description.errors }">
                    <div *ngIf="submitted && fundr2.description.errors" class="invalid-feedback">
                      <div *ngIf="fundr2.description.errors.required">This field is required</div>
                      </div>
                  </div>
               </div>
             <!--  -->
         
        
            <!-- using two similar templates for simplicity in js code -->
       
             <!--  -->
              <!-- Script -->
               <div class="row ml-2">
                <div class="col-md-12  mt-5">

                    <p class="doc-size">Upload Photo</p>
                    <div class="row">
                        <div class="col-md-4 py-2 px-2" style="height: 140px;border-radius: 10px;
                        background-color: #e8e5e5;">
                         
                            <div class="text center"style="border:dashed 1px #000000; height: 100%; width: 100%;position: relative; ">
                              
                                <div class="upload">
                                  <h1 class="file mt-2" style="color:black; width:36px; height:20%;margin: 0px auto; cursor: pointer;">

                                    <img src="assets/images/icons/upload.png">
                                  </h1>
                                  <input type="file"  #attachments name="files" (change)="fileProgress($event)" accept=".jpg,.png,.jpeg" formControlName="photo"  id="exampleInputFile" name="fileName" >
                                    <!-- <input type="file" name="files" #attachments type="file" formControlName="photo"  (change)="fileProgress($event)" accept=".jpg,.png,.jpeg"  id="exampleInputFile" name="fileName" --
                                    style="margin-top: 6%;width: 50%; margin-left: 19%;opacity: 0; cursor:pointer;position:absolute" [ngClass]="{ 'is-invalid': submitted && fundr2.photo.errors }" >
                                     -->

                                    <!-- <p style="color:black;text-align: center;
                                  ">Upload Main Image </p> -->
                                </div>

                            </div>
                        </div>
                        <div class="col-md-8">
                          <div class="col-md-3" *ngFor="let list of thumbFile;let index = index">
                            <span class="close-img img-cls" mat-icon-button (click)="removeSelectedFile(index)"><img  src="assets/images/icons/close.png" height="20" width="20" alt=""></span>
                              <img class="rounded  img-box"  [src]="list" alt="" style="margin: 10px;">
                          </div>
                        </div>
                      </div>
                      <div *ngIf="submitted && fundr2.photo.errors" class="invalid-feedback">
                        <div *ngIf="fundr2.photo.errors.required">This field is required</div>
                        </div>
                        <div *ngIf="errorMessage"  style="color: red;">{{errorMessage}}</div>


                </div>
            </div>

            <div class="row ml-2 mt-2">
              <div class="col-md-12">
                  <p class="doc-size">Upload Documents</p>
                  <div class="row">
                      <div class="col-md-4 py-2 px-2" style="height: 140px;border-radius: 10px;
                      background-color: #e8e5e5;">
                          <div class="text center"style="border:dashed 1px #000000; height: 100%; width: 100%;position: relative; ">

                              <div class="upload">
                                  <!-- <input type="file" #attachments type="file" formControlName="documents" (change)="uploadPatientDocuments($event)" accept=".png,.jpeg"  id="exampleInputFile" name="fileName" multiple="multiple"
                                  style="margin-top: 6%;width: 50%; margin-left: 19%;opacity: 0; cursor:pointer;position:absolute"  [ngClass]="{ 'is-invalid': submitted && fundr2.documents.errors }"> -->
                                  <h1 class="file mt-2" style="color:black; width:36px; height:20%; margin: 0px auto; cursor: pointer;"><img src="assets/images/icons/upload.png">
                                  </h1>
                                  <input type="file" #attachments type="file" formControlName="documents" (change)="uploadPatientDocuments($event)" accept="image/*, .pdf"  id="exampleInputFile" name="fileName" multiple="multiple">
                                  <!-- <p style="color:black;text-align: center;
                                ">Upload Multiple Documents </p> -->
                              </div>
                            </div>
                      </div>
                      <div class="col-md-8">
                        <div class="col-md-3" *ngFor="let list of documentThumbFile;let index = index">
                          <span class="close-img img-cls" mat-icon-button (click)="removePatientDocument(index)">
                            <img src="assets/images/icons/close.png"  *ngIf="(list.filetype === 'image/png') || (list.filetype === 'image/jpeg') ||(list.filetype == 'application/pdf') ||(list.filetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')" height="20" width="20" alt=""></span>
                            <img class="rounded img-box" *ngIf="(list.filetype == 'image/png') || (list.filetype == 'image/jpeg')"  [src]="list.fileval" alt="" style="margin: 10px;" >
                            <img class="rounded img-box" *ngIf="(list.filetype == 'application/pdf') || (list.filetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')"  src="assets/images/pdf.png" alt="" style="margin: 10px;" >
                          
                        </div>
                      </div>
                    </div>
                    <div *ngIf="submitted && fundr2.documents.errors" class="invalid-feedback">
                      <div *ngIf="fundr2.documents.errors.required">This field is required</div>
                      </div>
                      <div *ngIf="errorMessagedoc"  style="color: red;">{{errorMessagedoc}}</div>

              </div>
          </div>
         
          <div class="row ml-2">
            <div class="col-md-12">
                <p class="doc-size">Upload Beneificiary Image <span style="color: white;"> </span></p>
                <div class="row">
                    <div class="col-md-4 py-2 px-2" style="height: 140px;border-radius: 10px;
                    background-color: #e8e5e5;">
                             <h1 class="file mt-2" style="color:black; width:36px; height:20%;margin: 0px auto; cursor: pointer;">

                              <img src="assets/images/icons/upload.png">
                            </h1><br>
                     <input type="file" #attachments type="file" (change)="uploadBenefitiaryImage($event)" accept=".jpg,.png,.jpeg" formControlName="benefitiary_image"  [ngClass]="{ 'is-invalid': submitted && fundr2.benefitiary_image.errors }" id="exampleInputFile" name="fileName">
                       
                     <!-- <div class="text center"style="border:dashed 1px #000000; height: 100%; width: 100%;position: relative; ">
                           
                          
                            <div *ngIf="!files.length" class="col-12 rmpm dropzone" appDrag (files)="filesDropped($event)"  accept=".jpg,.png,.jpeg" [ngClass]="{ 'is-invalid': submitted && fundr2.benefitiary_image.errors }"  id="exampleInputFile" name="fileName">
                              <div class="text-wrapper">
                                <div class="centered">Drop your file here!</div>
                              </div>
                            </div>
                        </div> -->
                     
                      
                    
                 
                    </div>
                    <div class="col-md-8">
                      <div class="col-md-3">
<div *ngFor="let file of files">
  <span id="d1" mat-icon-button (click)="removeBenefitiaryImage(index)"><img src="assets/images/icons/close.png" height="20" width="20" alt=""></span>

                          <img *ngIf="file" [src]="file.url" width="100px" height="100px">
                        </div>
                      </div>
                      <div class="col-md-3" *ngFor="let list of benefitiaryThumbFile;let index = index">
                        <span class="close-img img-cls" mat-icon-button (click)="removeBenefitiaryImage(index)"><img src="assets/images/icons/close.png" height="20" width="20" alt=""></span>
                          <img class="rounded img-box"   [src]="list" alt="" style="margin: 10px; ">
                      </div>
                    </div>
                  </div>
                  <p *ngIf="status==1"><span style="color:red">{{this.msg}}</span></p>
                  <div *ngIf="submitted && fundr2.benefitiary_image.errors" class="invalid-feedback">
                    <div *ngIf="fundr2.benefitiary_image.errors.required">This field is required</div>
                    </div>
            </div>
        </div>


           </div>
				</div>
			<div class="col-md-12 col-sm-12 col-12">
				<div class="st-fd-form">
					<h3>Benificiary account details</h3>
					<div class="form-row">
          	<div class="form-group col-md-6 col-sm-6">
            		<input type="text" class="st-fd-tbox"  placeholder="Account Holder Name" formControlName="account_holder_name" [ngClass]="{ 'is-invalid': submitted && fundr2.account_holder_name.errors }">
                <div *ngIf="submitted && fundr2.account_holder_name.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.account_holder_name.errors.required">This field is required</div>
                  </div>
            	</div>
              <div class="form-group col-md-6 col-sm-6">
            		<input type="text" class="st-fd-tbox"  placeholder="IFSC Code" [maxLength]="11" style="text-transform: uppercase" formControlName="ifsccode"(input)="getAccountDetails()" [ngClass]="{ 'is-invalid': submitted && fundr2.ifsccode.errors }">
                <div *ngIf="submitted && fundr2.ifsccode.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.ifsccode.errors.required">This field is required</div>
                  <div *ngIf="fundr2.ifsccode.errors.pattern">Please, Enter valid Ifsc code.</div>
                  </div>
            	</div>
            	<div class="form-group col-md-6 col-sm-6">
            		<input type="text" class="st-fd-tbox"  placeholder="Account Number" formControlName="account_number" [ngClass]="{ 'is-invalid': submitted && fundr2.account_number.errors }">
                <div *ngIf="submitted && fundr2.account_number.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.account_number.errors.required">This field is required</div>
                  </div>
            	</div>
            	<div class="form-group col-md-6 col-sm-6">
            		<input type="text" class="st-fd-tbox"  placeholder="Bank Name" formControlName="bank" readonly [(ngModel)]="BANK" [ngClass]="{ 'is-invalid': submitted && fundr2.bank.errors }">
               <div *ngIf="submitted && fundr2.bank.errors" class="invalid-feedback">
                  <div *ngIf="fundr2.bank.errors.required">This field is required</div>
                  </div>
            	</div>
              <div class="form-group col-md-6 col-sm-6">
            		<input type="text" class="st-fd-tbox"  placeholder="Branch" formControlName="branch" readonly [(ngModel)]="BRANCH">
                 <!-- <span class="text-muted" style="font-size: 12px;">{{BRANCH}}</span> -->

            	</div>
           </div>
				</div>
			</div>
      <div class="col-md-12 col-sm-12 col-12">
				<div class="st-fd-form" style="max-height: 141px;
        min-height: 140px;">
					<h4 style="padding: 8px 0px 0px 0px;">Do you need any assist ?</h4>
					<div class="form-row mt-2" >
            <mat-radio-group aria-label="Select an option" formControlName="pricing_id" (change)="changePrice()">
              <mat-radio-button class="ml-2" *ngFor="let item of priceList" value="{{item.id}}">{{item.title}}({{item.percentage}}%)</mat-radio-button>
            </mat-radio-group>
           </div>
           <div class="form-row "  >
            <span *ngIf="priceValue == 1" class="ml-3">{{priceList[0].description}}</span>
            <span *ngIf="priceValue == 2" class="ml-3">{{priceList[1].description}}</span>
            <span *ngIf="priceValue == 3" class="ml-3">{{priceList[2].description}}</span>
              
           </div>
           <p style="font-size:14px"><span style="color:#dc3545;margin-left: 30px;font-size:15px">*</span> Bank Charges deducted</p>
				</div>
			</div>
    

      <div class="row col-12" >
        <button class="btn btn-sm fundraiser-btn sbmt-fund"  type="button"(click)="submitFundRaiser($event)"> <i class="fa" [ngClass]="{'fa-spin fa-spinner': loading}"></i><span>Submit</span></button>
      </div>

    </form>
		</div>
	</div>

</section>
<!-- Message popup Modal -->
<div class="modal fade payed-modal" id="successFundModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="background-color: white;">
      <div class="modal-body">
        <div class="col-lg-12 col-lg-12">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="payd-msg">
                <p class="mt-4" style="color: black;text-transform: none;">
                  Your application submitted successfully.We will verify as soon as possible.</p>
                <div class="row">
                  <div class="col-md-2 col-12"></div>
                  <div class="col-md-4 col-12" style=" margin: 0 auto;">
                    <button class="f-btn-border" type="submit" data-dismiss="modal" style="background-color: #32374a;" (click)="goToProfile()" >OK</button>
                  </div>

                  <div class="col-md-2 col-12"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
