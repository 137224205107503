<div class="container" id="PrivacyPolicy">
  <h2 class="text-center mt-5">Refund and Cancellation Policy</h2>
<div class="row mt-3">
  <div class="col-xs-12" style="padding: 0px 18px 0px 18px;">
<p>Donations made through Crowd Works India Foundation are final and cannot be refunded, except in accordance with Crowd Works India Foundation’s Refund Policy. All Donation payments are final and cannot be refunded. However, Crowd Works India Foundation will issue refunds for the following “exceptional” scenarios. Once the fundraiser has been paid out, no refund request will be accepted. </p>
<ul>
  <li class="ml-5">•	A Campaign has been identified as a fraudulent or violating the terms of service and no funds have yet been transferred to the Recipient towards the same. Crowd Works India Foundation has decided to refund all the donations to the donors</li>
  <li class="ml-5">•	A Donation has been identified to have been initiated using an unauthorised payment instrument not belonging to the user by the user(Donor) and the user wishes a refund</li>
  <li class="ml-5">•	Campaign organizer has posted an update that the intended purpose of the fundraising campaign is no longer possible and no funds have yet been transferred to the Recipient towards the same.</li>
</ul>
<p>Any other claims for refunds shall be honoured at the discretion of Crowd Works India Foundation Team.</p>
<p>Note:  All refunds will credited to the original mode of payment in 7-10 bank working days. The refund will be the amount donated minus the payment gateway charges as per the actuals i.e. approx 0.5%-3% per transaction. In the case of foreign payments (Non-INR), there could be forex conversion charges also from your respective bank. It would depend on the bank, card, country etc. It would be best to check with your bank.</p>
    </div>
    </div>
