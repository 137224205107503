
<!--slider section start-->
<section class="contact-us">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-lg-12">
				<div class="contact-adress cursr-none">
					<h2 > Contact us</h2>
					<!-- <li>{{contact_us_title}}</li> -->
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6 col-lg-6 col-sm-12 col-12">
				<div class="contact-dt cursr-none">
					<div class="ct-icon-bar">
          
              <img src="assets/images/icons/location.png"style="width:25px;height: 50px;" class="img-fluid">
              <p class="cadrs" style="font-size: 13px !important;"><br>Crowd Works India Foundation,
                <br> Alamparambil building, 13/1013-5,
                <br>2nd floor, tk road, Thiruvalla,Kerala, 689101.<br>Toll Free: 1800 890 1811<br>
                Time: Mon - Fri (9:00 - 19:00)</p>
              <div class="clearfix"></div>
            </div>
            <div class="ct-icon-bar"><br>
              <img src="assets/images/icons/email.png" style="width:30px;height: 50px;"class="img-fluid">
              <p class="cmail" style="font-size: 16px;">info@crowdWorksIndia.org</p>
            </div>
            <div class="ct-icon-bar">
              <img src="assets/images/icons/phone.png" style="width:30px;height: 50px;"class="img-fluid">
              <p class="cmail" style="font-size: 16px;">+91 99460 31111</p>
            </div>
            </div>
          
				<div class="location-map">
          <iframe [src]="mapUrl" width="100%" height="480" frameborder="0" style="border:0" allowfullscreen></iframe>
				</div>
			</div>
			<div class="col-md-6 col-lg-6 col-sm-12 col-12">
				<div class="contact-form">
          <form [formGroup]="contactForm">

					<div class="form-group">
						<input type="text" name="" formControlName="name" class="input-box form-control" placeholder="Your Name*" [ngClass]="{ 'is-invalid': submitted && f2.name.errors }">
            <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
              <div *ngIf="f2.name.errors.required">This field is required</div>

              </div>
          </div>
					<div class="form-group">
						<input type="text" name="" formControlName="email" [email]="true"  class="input-box form-control" placeholder="E-mail*" [ngClass]="{ 'is-invalid': submitted && f2.email.errors }">
            <div *ngIf="submitted && f2.email.errors" class="invalid-feedback">
              <div *ngIf="f2.email.errors.required">This field is required</div>
              <div *ngIf="f2.email.errors.pattern">Please, Enter valid email.</div>

              </div>
          </div>

					<div class="form-group">
						<textarea class="textarea-div form-control" name="" formControlName="message" placeholder="Comment*" [ngClass]="{ 'is-invalid': submitted && f2.message.errors }"></textarea>
            <div *ngIf="submitted && f2.message.errors" class="invalid-feedback">
              <div *ngIf="f2.message.errors.required">This field is required</div>

              </div>
          </div>
					<!-- <div class="form-group"> -->
						<button type="submit" class="contact-btn" (click)="submit()"><i class="fa" [ngClass]="{'fa-spin fa-spinner': loading}"></i>Submit</button>
					<!-- </div> -->
        </form>
        </div>

			</div>
		</div>
	</div>
</section>

<script
src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap&libraries=&v=weekly"
async
></script>
 <!-- Message popup Modal -->
 <div class="modal fade payed-modal" id="successcontactusModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="background-color: white;">
      <div class="modal-body">
        <div class="col-lg-12 col-lg-12">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="payd-msg">
                <p class="mt-4" style="color: black;text-transform: none;">
                  {{message}}</p>
                <div class="row">
                  <div class="col-md-2 col-12"></div>
                  <div class="col-md-4 col-12" style=" margin: 0 auto;">
                    <button class="f-btn-border" type="submit" data-dismiss="modal" style="background-color: #32374a;" >OK</button>
                  </div>

                  <div class="col-md-2 col-12"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
