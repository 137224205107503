

<div class="page-banner">
	<div class="page-1400">
		<div id="carousel" class="carousel slide carousel-fade" data-ride="carousel">
			<div class="carousel-inner fund-title">
        <owl-carousel-o [options]="customOptions2">
          <ng-template carouselSlide  *ngFor="let item of bannerList ,let i = index" [class]="(i === 0) ? 'active' : 'inactive'">
            <div class="page-caption" >
              <h3>{{item.title}}</h3>
              <button type="" style="width:170px"class="donation" routerLink="/fund-raiser-detail/{{item.id}}"><i class="fa fa-heart" aria-hidden="true"></i> &nbsp;Donate Now</button>
            </div>
          </ng-template>
        </owl-carousel-o>
			</div>
		</div>
	</div>
</div>
<!-- <div class="sec-views">
	 <div class="container">
		<div class="view-div-bar">
			<div class="row">
				<div class="col-md-12 col-lg-12">
					<div class="view-div">
						<div class="view-list text-red">
							<h3 class="text-red">₹1500 +Cr*</h3>
							<p class="text-red">Sposoring the education of 6 </p>
						</div>
					</div>
					<div class="view-div">
						<div class="view-list">
							<h3>5 lakh +</h3>
							<p>live impact acroos the globe </p>
						</div>
					</div>
					<div class="view-div">
						<div class="view-list">
							<h3>165 +</h3>
							<p>No.of countries raised funds from</p>
						</div>
					</div>
					<div class="view-div">
						<div class="view-list">
							<h3>₹ 30 Lakh</h3>
							<p>No.of countries raised in 24hr</p>
						</div>
					</div>
					<div class="view-div">
						<div class="view-list">
							<h3>80G</h3>
							<p>80G Tax benefits for indian donors</p>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div> -->
 <div class="fund-search">
	<div class="container">
    <form [formGroup]="filterForm">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6 col-lg-8 col-sm-12 col-12">
              <input type="text" class="search-fund-box" formControlName="seachKeyword"  (input)="searchFundRaiserByKeword()" placeholder="Search" style="color: black !important;">
            </div>
            <div class="col-md-3 col-lg-2 col-sm-12 col-12">
              <select class="search-fund-box" formControlName="amount" [(ngModel)]="sortrVal"  (change)="sortFundRaiser()">
                <option value="" >Sort</option>
                <option value="asc" selected="selected" >Low To High</option>
                <option value="desc" >High To Low</option>
              </select>
            </div>
            <div class="col-md-3 col-lg-2 col-sm-12 col-12 lend-btn">
              <button type="button" class="donation create-btn" (click)="createlendModal()"><i class="fa fa-heart" style="display: none;" aria-hidden="true"></i>Create</button>
            </div>
            <!-- <div class="col-md-2 col-lg-2 col-sm-12 col-12">
              <div class="dropdown">
                  <button class="search-fund-box dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   Filter
                  </button>
                  <ul class="dropdown-menu dropdown-filter" role="menu">
                          <li class="check-item"  *ngFor="let item of categoryList">
                                <span class="check-item-left">
                                    <input type="checkbox" #checkboxes name="" formControlName="category_id"   (change)="categoryVal(item.title,item.id)">
                                    <span class="check-item-info">
                                        <span>{{item.title}}</span>
                                    </span>
                                </span>
                          </li>
                          <li class="divider"></li>
                          <li><button (click)="clearCheckedItems()">cancel</button><button (click)="filterCampagin()">apply</button></li>
                      </ul>
                    </div>

            </div> -->
          </div>
          <!-- <div class="row">
            <div class="col-md-12">
              <button type="submit" class="fund-category-btn" *ngFor="let item of campaigns; let i=index">
                 {{item}}<i class="fa fa-times" aria-hidden="true" (click)="removecampagin(i)"></i>
              </button>
            </div>
          </div> -->
        </div>
      </div>
      </form>
	</div>
</div>
<p style="text-align: center;" class="cursr-none lend-head"><b>You can help a family Fundraise for a better life.Make a Loan </b></p>

 <div class="section-fundraiser">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
        <div class="d-flex justify-content-center" *ngIf="loanList == undefined">
          <div class="spinner-border text-success m-5 "  role="status"  >
            <span class="sr-only" >Loading...</span>
          </div>
          </div>
        <div class="d-flex justify-content-center">
          <div class="m-5"  role="status" *ngIf="lendLength === 0"  ><h3 style="color:#c3313e;"> No Records Found</h3>
          </div>
          </div>
				<div class="row" >
					<div class="col-md-4 col-lg-4" *ngFor="let item of loanList | paginate: {
            itemsPerPage: 18,
            currentPage: pageNumber,
            totalItems: totalCount
          }">
						<div class="slide-scheme">
              <div class="campaign-img">

							<img src="{{baseUrlLoan}}{{item.image_url}}" class="img-fluid" style="cursor: pointer;" routerLink="/loan-details/{{item.id}}">
            </div>
							<div class="scheme-subject mt-2">
								<h5 class="h3-txt">{{item.title.length>70 ? (item.title | slice:0:70)+'..':(item.title)}}</h5>
								<span>₹{{item.amount}}</span>
								<div class="social-link">
                  <li class="mt-1 cursr-pointer">share now</li>
                  <share-buttons [theme]="'modern-dark'" style="margin-left:10px;"
                  [include]="['facebook','twitter','linkedin','whatsapp']"
                  [show]="4"
                  [size]="1"
                  [url]="'https://crowdworksindia.org/#/fund-raiser-detail/' + item.id"
                  [description]="story"

                  [image] ="'https://www.eduforbetterment.com/wp-content/uploads/2020/08/qrcode.jpg'"
                  [autoSetMeta]="false"
                  ></share-buttons>
								</div>
              </div>

              <div class="scheme-subject mt-2"  *ngIf="(loginToken | async)">
                <div class="row" style="display:flex" routerLink="/loan-details/{{item.id}}">
                  <div class="col-12 col-md-5 col-sm-12 col-lg-5" >
                    <button class="btn-w100-rd"  >Lend</button>

                  </div>

                      <div class="col-12 col-sm-12 col-lg-7 col-md-7" >
                        <button class="btn-w100-rd" (click)="completeLoan()" routerLink="/loan-details/{{item.id}}" style="background-color: #c3313e;
                    color: white;margin: 1px;">Complete The Loan</button>

                      </div>
                   </div>
                  </div>
                <!-- <div class="col-md-12 col-12" style="display:flex" routerLink="/loan-details/{{item.id}}">
                  <div class="col-4 col-md-4" style="padding-right: 4px !important;
                  padding-left: 0px; !important">
                    <button class="btn-w100-rd"  >Lend</button>

                  </div>
                  <div class="col-8 col-md-8" style="padding-right: 4px !important;
                  padding-left: 15px; !important">
                    <button class="btn-w100-rd" (click)="completeLoan()" routerLink="/loan-details/{{item.id}}" style="background-color: #c3313e;
                    color: white;margin: 1px;">Complete The Loan</button>

                  </div>
                </div> -->
                <!-- <div class="col-md-12 col-12 mt-2" style="display:flex"></div> -->
        <!-- <div class="col-md-12">
          <div class="form-group">
            <div class="form-row">
              <div class="form-group col-md-3 col-7" style="color: white;">
                <span>Total:&nbsp;<b>₹ 25000 </b> </span>
              </div>
          <div class="form-group col-md-9 col-9">
            <button class="pull-right btn-wz-wt btn-next" mat-button matStepperNext>Pay Now </button>
          </div>
        </div>
        </div>
        </div> -->

							</div>
						</div>
					</div>


			</div>
		</div>
	</div>
  <div class="row" *ngIf="totalCount > 18">
    <div class="col-md-12">
    <div class="container d-flex justify-content-center">
      <pagination-controls
      (pageChange)="pageChanged($event)"
    ></pagination-controls>
    </div>

    </div>

</div>
</div>


<div class="modal fade lend" id="create-lend-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content modal-width">
			<div class="modal-header">
				<button type="button" class="close  btn-close1" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="col-xl-12 col-lg-12">
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 ">

              <form [formGroup]="createLendForm">
                <div class="row">
									<div class="col-md-12 mb-20">
										<div class="sign-in-form">
											<h3 class="gn-h2" style="color: white !important;">Create for Loan</h3>

                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-12">
                          <!-- <div class="col-md-12  mt-5"> -->
                            <!-- <p style="color: #32374a;">Upload Documents <span style="color: #32374a;"> pictures and videos of you Experience (rental or lesson). All photos and videos are required
                                to be yours. Drag to reorder</span>.</p> -->
                            <div class="row">
                                <div class="col-md-5 py-2 px-2" style="height: 140px;border-radius: 10px;
                                background-color: lightgray;">
                                    <div class="text center"style="border:dashed 1px #1583DD; height: 100%; width: 100%;position: relative; ">

                                        <div class="upload">
                                            <input type="file" #attachments type="file" formControlName="image" (change)="uploadImage($event)" accept=".jpg,.png,.jpeg"  id="exampleInputFile" name="fileName"
                                            style="margin-top: 6%;width: 50%; margin-left: 19%;opacity: 0; cursor:pointer;position:absolute">
                                            <h1 class="file mt-2" style="color:black; width:20%; height:20%; margin-left:40%; cursor: pointer;"><img src="assets/images/icons/upload.png">
                                            </h1>

                                            <p style="color:black;text-align: center;
                                          ">Upload photo </p>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-7">
                                  <div class="col-md-7" *ngFor="let list of thumbFile;let index = index">
                                    <span class="close-img img-cls" mat-icon-button (click)="removeSelectedFile(index)"><img src="assets/images/icons/close.png" height="15" width="15" alt=""></span>
                                      <img class="rounded"  [src]="list" alt="" style="margin: 10px;" height="70"  width="70">
                                  </div>
                                </div>
                              <!-- </div> -->
                        </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-12">
                  <input name="username" class=" form-control lend-box  inp-backcolor" formControlName="title" placeholder="Title" type="text" [ngClass]="{ 'is-invalid': submitted && f2.title.errors }" >
                  <div *ngIf="submitted && f2.title.errors" class="invalid-feedback">
                    <div *ngIf="f2.title.errors.required">This field is required</div>
                    </div>
                  <input name="username" class=" form-control lend-box  inp-backcolor" formControlName="purpose" placeholder="Purpose" type="text" [ngClass]="{ 'is-invalid': submitted && f2.purpose.errors }" >
                  <div *ngIf="submitted && f2.purpose.errors" class="invalid-feedback">
                    <div *ngIf="f2.purpose.errors.required">This field is required</div>
                    </div>
                  <input name="username" class=" form-control lend-box  inp-backcolor" formControlName="amount" placeholder="Amount" type="number" [ngClass]="{ 'is-invalid': submitted && f2.amount.errors }" >
                  <div *ngIf="submitted && f2.amount.errors" class="invalid-feedback">
                    <div *ngIf="f2.amount.errors.required">This field is required</div>
                    </div>

                </div>
                </div>

                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-sm-12 col-12">
											<input name="username" class=" form-control lend-box  inp-backcolor" formControlName="location" placeholder="Location" type="text" [ngClass]="{ 'is-invalid': submitted && f2.location.errors }" >
                      <div *ngIf="submitted && f2.location.errors" class="invalid-feedback">
                        <div *ngIf="f2.location.errors.required">This field is required</div>
                        </div>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-12">
                    <input name="username" class=" form-control lend-box  inp-backcolor" formControlName="no_of_days" placeholder="Min No.of Days For Amount Collection" type="text" [ngClass]="{ 'is-invalid': submitted && f2.no_of_days.errors }" >
                    <div *ngIf="submitted && f2.no_of_days.errors" class="invalid-feedback">
                      <div *ngIf="f2.no_of_days.errors.required">This field is required</div>
                      </div>
                  </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-12 col-12">
                <input name="username" class=" form-control lend-box  inp-backcolor" formControlName="description" placeholder="Description" type="text" [ngClass]="{ 'is-invalid': submitted && f2.description.errors }" >
                <div *ngIf="submitted && f2.description.errors" class="invalid-feedback">
                  <div *ngIf="f2.description.errors.required">This field is required</div>
                  </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-12">
              <button type="submit" class="btn-wd-100" (click)="createLend()"><i class="fa" [ngClass]="{'fa-spin fa-spinner': loading}"></i> Submit</button>
            </div>
            </div>
                    </div>

										</div>
									</div>
                </form>
						</div>
						<div class="col-lg-6 col-md-6">

						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>
<div class="modal fade wizard-tab-modal " id="loan-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <button type="button" class="close btn-close1" data-dismiss="modal"  aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="col-md-12 col-lg-12 form-wizard">
          <mat-horizontal-stepper linear #stepper >
            <mat-step [stepControl]="loanfirstFormGroup" >
              <form [formGroup]="loanfirstFormGroup">
                <h4 style="color: white;">Lend</h4>

                <div class="row">

                  <div class="col-md-12 mt-1">
                      <div class="form-group">
                          <div class="input-group input-group-lg">
                            <div class="input-group-prepend">
                              <span class="input-group-text wz-ip-grp"><i class="fa fa-inr"  aria-hidden="true"></i></span>
                            </div>
                            <input class="form-control wz-txt-box required" type="text" placeholder="1000" [(ngModel)]="lendAmount" formControlName="amount" aria-label="Search"  size="50">
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                     <div class="form-row">
                        <div class="form-group col-md-2 col-4">
                            <button class="btn-block rs-block btn-color" (click)="lendAmounts($event)" value="1000">₹1000</button>
                        </div>
                        <div class="form-group col-md-2 col-4">
                            <button class="btn-block rs-block" (click)="lendAmounts($event)" value="2000">₹2000</button>
                        </div>
                        <div class="form-group col-md-2 col-4">
                            <button class="btn-block rs-block" (click)="lendAmounts($event)" value="3000">₹3000</button>
                        </div>
                        <div class="form-group col-md-3 col-4">
                            <button class="btn-block rs-block" (click)="lendAmounts($event)" value="4000">₹4000</button>
                        </div>
                        <div class="form-group col-md-3 col-4">
                          <button class="btn-block rs-block" (click)="lendAmounts($event)" value="4000">₹5000</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <button class="pull-right btn-wz-wt btn-next" mat-button matStepperNext>Pay {{loanfirstFormGroup.value.amount}} </button>
                    </div>
                  </div>
                </div>

              </form>
            </mat-step>

            </mat-horizontal-stepper>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade payed-modal" id="successlendModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="background-color: white;">
      <div class="modal-body">
        <div class="col-lg-12 col-lg-12">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="payd-msg">
                <p class="mt-4" style="color: black;text-transform: none;">
                  Your application submitted successfully.</p>
                <div class="row">
                  <div class="col-md-2 col-12"></div>
                  <div class="col-md-4 col-12" style=" margin: 0 auto;">
                    <button class="f-btn-border" type="submit" data-dismiss="modal" style="background-color: #32374a;" >OK</button>
                  </div>

                  <div class="col-md-2 col-12"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- DONATE function start -->


<div class="modal fade wizard-tab-modal " id="wizard-up-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <button type="button" class="close btn-close1" (click)="closeModal()"  aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="col-md-12 col-lg-12 form-wizard">
          <mat-horizontal-stepper linear #stepper >
            <mat-step [stepControl]="firstFormGroup" >
              <form [formGroup]="firstFormGroup">
                <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                          <div class="input-group input-group-lg">
                            <div class="input-group-prepend">
                              <span class="input-group-text wz-ip-grp"><i class="fa fa-inr" aria-hidden="true"></i></span>
                            </div>
                            <input class="form-control wz-txt-box required" type="text" placeholder="1000" [(ngModel)]="donationAmount" formControlName="amount" aria-label="Search"  size="50">
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                     <div class="form-row">
                        <div class="form-group col-md-2 col-4">
                            <button class="btn-block rs-block btn-color" (click)="donAmount($event)" value="1">1000</button>
                        </div>
                        <div class="form-group col-md-2 col-4">
                            <button class="btn-block rs-block" (click)="donAmount($event)" value="2000">2000</button>
                        </div>
                        <div class="form-group col-md-2 col-4">
                            <button class="btn-block rs-block" (click)="donAmount($event)" value="3000">3000</button>
                        </div>
                        <div class="form-group col-md-3 col-4">
                            <button class="btn-block rs-block" (click)="donAmount($event)" value="4000">4000</button>
                        </div>
                        <div class="form-group col-md-3 col-4">
                          <button class="btn-block rs-block" (click)="donAmount($event)" value="4000">5000</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <button class="pull-right btn-wz-wt btn-next" mat-button matStepperNext>NEXT</button>
                    </div>
                  </div>
                </div>

              </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" >
              <form [formGroup]="secondFormGroup">
                <h3 class="wz-h3 mb-20">Donor informations</h3>
                <div class="row">
                  <div class="col-md-12">
                    <div class="donate-form">
                      <div class="form-row">
                        <div class="form-group col-md-6 mb-10">
                          <input type="text" class="form-control donor-txt-box " formControlName="name"  placeholder="* Full name" [ngClass]="{ 'is-invalid': submitted && f3.name.errors }">
                          <div *ngIf="submitted && f3.name.errors" class="invalid-feedback">
                            <div *ngIf="f3.name.errors.required" class="errr-msg-white">This field is required</div>
                            </div>

                            <div class="login-toggle-btn">
                                <input type="checkbox" (click)="showinfo($event)" formControlName="show_donor_information">
                                <label>make my donation anonymous</label>
                            </div>

                        </div>
                        <div class="form-group col-md-6 mb-10">
                          <input type="text" class="form-control donor-txt-box inp-backcolor"  [email]="true"  formControlName="email"  placeholder="* Email" [ngClass]="{ 'is-invalid': submitted && f3.email.errors }">
                          <div *ngIf="submitted && f3.email.errors" class="invalid-feedback errr-msg-white">
                            <div *ngIf="f3.email.errors.required">This field is required</div>
                            <div *ngIf="f3.email.errors.pattern">Please, Enter valid email.</div>


                            </div>
                        </div>
                        <div class="form-group col-md-6 mb-30">
                          <input type="hidden" class="form-control donor-txt-box"  placeholder="* phone number">
                        </div>
                        <div class="form-group col-md-6 mb-20">
                            <button class="btn-wz-wt w-100" (click)="submitDonationDetails()"> proceed to pay ₹{{firstFormGroup.value.amount}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- <button mat-button matStepperPrevious class="btn-wz-wt">Back</button> -->
                  <!-- <button mat-button matStepperNext></button> -->
                </div>
              </form>
            </mat-step>
            </mat-horizontal-stepper>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade payed-modal" id="gmFormmodal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="col-lg-12 col-lg-12">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="payd-msg">
                <p class="mt-4">
                  Do you want a 80g form?</p>
                <div class="row">
                  <div class="col-md-2 col-12"></div>
                  <div class="col-md-4 col-12">

                    <button class="f-btn-border-red" type="submit" (click)="open80gForm(data)">Yes</button>
                  </div>
                  <div class="col-md-4 col-12">
                    <button class="f-btn-border" type="submit" (click)="submitPayment(data)">No</button>
                  </div>
                  <div class="col-md-2 col-12"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</div>

<div class="modal fade eighty-gmform-modal" id="80gmFormmodal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content modal-width">
			<div class="modal-header">
				<button type="button" class="close" (click)="close80gForm()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
      <div class="modal-body">
        <form [formGroup]="eightygmForm">
          <h3 class="wz-h3 mb-20">80g Form</h3>
          <div class="row">
            <div class="col-md-12">
              <div class="donate-form">
                <div class="form-row" >
                  <div class="form-group col-md-6 mb-10" style="padding-bottom: 20px;">
                    <input type="text" class="form-control donor-txt-box " formControlName="pancard_no" placeholder="* Pan Card Number" [ngClass]="{ 'is-invalid': submittedgmForm && f4.pancard_no.errors }">
                    <div *ngIf="submittedgmForm && f4.pancard_no.errors" class="invalid-feedback">
                      <div *ngIf="f4.pancard_no.errors.required" class="errr-msg-white">This field is required</div>
                      <div *ngIf="f4.pancard_no.errors.pattern" class="errr-msg-white">Invalid pan card number.</div>

                      </div>
                  </div>
                  <div class="form-group col-md-6 mb-10" style="padding-bottom: 20px;">
                    <input type="text" class="form-control donor-txt-box inp-backcolor"formControlName="name"  placeholder="* Name" [ngClass]="{ 'is-invalid': submittedgmForm && f4.name.errors }">
                    <div *ngIf="submittedgmForm && f4.name.errors" class="invalid-feedback errr-msg-white">
                      <div *ngIf="f4.name.errors.required">This field is required</div>
                      </div>
                  </div>

                  <div class="form-group col-md-6 mb-30 " style="padding-bottom: 20px;">
                    <input type="number" class="form-control donor-txt-box inp-backcolor" formControlName="phone_number"  placeholder="* Phone Number" [ngClass]="{ 'is-invalid': submittedgmForm && f4.phone_number.errors }">
                    <div *ngIf="submittedgmForm && f4.phone_number.errors" class="invalid-feedback errr-msg-white">
                      <div *ngIf="f4.phone_number.errors.required">This field is required</div>
                      </div></div>
                      <div class="form-group col-md-6 mb-30 " style="padding-bottom: 20px;">
                        <input type="text" class="form-control donor-txt-box inp-backcolor" formControlName="address"  placeholder="* Address" [ngClass]="{ 'is-invalid': submittedgmForm && f4.address.errors }">
                        <div *ngIf="submittedgmForm && f4.address.errors" class="invalid-feedback errr-msg-white">
                          <div *ngIf="f4.address.errors.required">This field is required</div>
                          </div></div>
                  <div class="form-group col-md-12 mb-20 " style="padding-bottom: 20px;">
                      <button class="btn-wz-wt w-50" (click)="submit80gForm(data)" style="float: right;" > Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- <button mat-button matStepperPrevious class="btn-wz-wt">Back</button> -->
            <!-- <button mat-button matStepperNext></button> -->
          </div>
        </form>
    </div>

  </div>
</div>
</div>
<div class="modal fade payed-modal" id="closeDonateModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="col-lg-12 col-lg-12">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="payd-msg">
                <p class="mt-4 ">
                  I Could Really Use Your Help Right Now.You Can Help Me with a Contribution As Small as ₹300.</p>
                <div class="row">
                  <div class="col-md-2 col-12"></div>
                  <div class="col-md-4 col-12">
                    <button class="f-btn-border" type="submit" data-dismiss="modal" >No, Thanks</button>
                  </div>
                  <div class="col-md-4 col-12">
                    <button class="f-btn-border-red" type="submit" (click)="sureIwillhelp()" >SureI'll Help</button>
                  </div>
                  <div class="col-md-2 col-12"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- #c3313e -->
<ngx-spinner color="skyblue" type="ball-fussion"><span style="color:white ;">Please wait...</span></ngx-spinner>

<div class="modal fade payed-modal" id="successPaymentModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="background-color: white;">
      <div class="modal-body">
        <div class="col-lg-12 col-lg-12">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="payd-msg">
                <p class="mt-4" style="color: black;text-transform: none;">
                  Thank you for donating the amount,receipt will be send to your Phone number/Email id..</p>
                <div class="row">
                  <div class="col-md-2 col-12"></div>
                  <div class="col-md-4 col-12" style=" margin: 0 auto;">
                    <button class="f-btn-border" type="submit" data-dismiss="modal" style="background-color: #32374a;" >OK</button>
                  </div>

                  <div class="col-md-2 col-12"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="modal fade wizard-tab-modal " id="newwizard-up-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <button type="button" class="close btn-close1" (click)="closeModal()"  aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="col-md-12 col-lg-12 form-wizard">
          <mat-horizontal-stepper linear #stepper >
            <mat-step [stepControl]="secondFormGroup" >
              <form [formGroup]="secondFormGroup">
                <h3 class="wz-h3 mb-20">Donor informations</h3>
                <div class="row">
                  <div class="col-md-12">
                    <div class="donate-form">
                      <div class="form-row">
                        <div class="form-group col-md-6 mb-10">
                          <input type="text" class="form-control donor-txt-box " formControlName="name"  placeholder="* Full name" [ngClass]="{ 'is-invalid': submitted && f3.name.errors }">
                          <div *ngIf="submitted && f3.name.errors" class="invalid-feedback">
                            <div *ngIf="f3.name.errors.required" class="errr-msg-white">This field is required</div>
                            </div>
                            <div class="login-toggle-btn">
                                <input type="checkbox" (click)="showinfo($event)" formControlName="show_donor_information">
                                <label>make my donation anonymous</label>
                            </div>
                        </div>
                        <div class="form-group col-md-6 mb-10">
                          <input type="text" class="form-control donor-txt-box inp-backcolor"  [email]="true"  formControlName="email"  placeholder="* Email" [ngClass]="{ 'is-invalid': submitted && f3.email.errors }">
                          <div *ngIf="submitted && f3.email.errors" class="invalid-feedback errr-msg-white">
                            <div *ngIf="f3.email.errors.required">This field is required</div>
                            <div *ngIf="f3.email.errors.pattern">Please, Enter valid email.</div>
                            </div>
                        </div>
                        <div class="form-group col-md-6 mb-30">
                          <input type="hidden" class="form-control donor-txt-box"  placeholder="phone number">
                        </div>
                        <div class="form-group col-md-6 mb-20" >
                            <button class="btn-wz-wt w-100" (click)="submitSmallDonation()"> proceed to pay ₹300</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- <button mat-button matStepperPrevious class="btn-wz-wt">Back</button> -->
                  <!-- <button mat-button matStepperNext></button> -->
                </div>
              </form>
            </mat-step>
            </mat-horizontal-stepper>
        </div>
      </div>
    </div>
  </div>
</div>
