<html>
<!-- To make Layer checkout responsive on your checkout page.-->
<meta name="viewport" content="width=device-width, initial-scale=1" />

<!--Please add either of the following script to your HTML depending up on your environment-->

<!--For Sandbox--> 
<script id="context" type="text/javascript" 
src="https://sandbox-payments.open.money/layer"></script>

<!--OR-->

<!--For Production-->
<script id="context" type="text/javascript"
src="https://payments.open.money/layer"></script>

<body>
  <script>

//You can bind the Layer.checkout initialization script to a button click event.
//Binding inside a click event open Layer payment page on click of a button
 Layer.checkout({
        token: {{payment_token}},
        accesskey: {{acces_key}},
        theme: {
            logo : "https://open-logo.png",
            color: "#3d9080",
            error_color : "#ff2b2b"
          }
    },
    function(response) {
    
        if (response.status == "captured") {
           // response.payment_token_id
           // response.payment_id
           window.location.href = "success_redirect_url";

        } else if (response.status == "created") {


        } else if (response.status == "pending") {


        } else if (response.status == "failed") {
          window.location.href = "failure_redirect_url";

        } else if (response.status == "cancelled") {
          window.location.href = "cancel_redirect_url";
        }
    },
    function(err) {
        //integration errors
    }
);
</script>
</body>